import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '@ionic/react/css/ionic.bundle.css';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import React, {useEffect, useState} from 'react';
import Header from "../views/Header";
import {useHistory, useLocation} from "react-router";
import Spinner from "../views/Spinner";
import {Button} from "../views/designElements";

export default function Scanner(){
  const [locationKeys, setLocationKeys] = useState([])
  const history = useHistory();
  const location = useLocation()
  const [compartmentUUID, setCompartmentUUID] = useState(null)

    const handleUpdate = (result) => {
      if(!result) {
          return null
      }
      //URL : http://localhost:3000/compartments/{compartmentUUID}/open
      const url = result.text
      const parts = url.split("/");
      const compartmentUUID = parts[4]
        setCompartmentUUID(compartmentUUID)
    }

    function redirectToHome() {
      history.push('/home');
    }

    useEffect(() => {
        console.log("location in Scanner")
        console.log(location)
        if(compartmentUUID != null){
            if(location.hasToMatch){
                if(location.requiresVerification){
                    if(location.hasToMatch !== compartmentUUID){
                        history.push({
                            pathname:'overview/'+location.lockerId,
                            showAlert: true
                        })
                    }
                    else{
                        history.push({
                            pathname:'compartments/'+ compartmentUUID+'/open',
                            comingFromOverview: location.comingFromOverview,
                            requiresVerification: true
                        })
                    }
                }
                else{
                    if(location.hasToMatch !== compartmentUUID){
                        history.push({
                            pathname:'overview/'+location.lockerId,
                            showAlert: true
                        })
                    }
                    else{
                        history.push({
                            pathname:'compartments/'+compartmentUUID+'/open',
                            comingFromOverview: location.comingFromOverview
                        })
                    }
                }
            }
            else{
                history.push({
                    pathname:'compartments/'+compartmentUUID+'/open',
                    comingFromOverview: location.comingFromOverview
                })
            }
        }
    } )

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
      
              history.push({
                pathname:'/home',
            })
      
      
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
      
              history.push({
                pathname:'/home',
            })
      
            }
          }
        })
    }, [ locationKeys, ])
  
  //QR url format: http://localhost:3000/compartments/{compartmentUUID}/open
   return (
       <div>
           <Header/>
           <div style={{marginLeft: "7%"}}>
               <p>Scan the QR code of the compartment you want to access.</p>
               <BarcodeScannerComponent
                   width={500}
                   height={500}
                   onUpdate={(err,result) => {handleUpdate(result)}}
               />
               <Spinner/>
               <Button style={{marginRight: '20px'}} onClick={ () => redirectToHome()}> Home </Button>
           </div>
        </div>
   );
}