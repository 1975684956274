import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../views/Header";
import "../views/stylesheet.css"
import {api, handleError} from "../helpers/api";
import OverviewLockerUI from "../views/LockerUIs/OverviewLockerUI";

export default function Shared(){
    const {token} = useParams();
    const {phonenumber} = useParams();
    const [userExists, setUserExists] = useState({data:null, loading:true});
    const [compartmentsOfUser, setCompartmentsOfUser] = useState({data:[], loading:true})
    const [lockerId, setLockerId] = useState({data:null, loading:true})
    const [compartments, setCompartments] = useState({data:[], loading:true})


    const userDoesExist = async() => {
        try{
            await api.get('/api/users/exists/'+localStorage.getItem('token'))
                .then(x => x.data)
                .then(y => {
                    setUserExists({data:y[0], loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request: \n${handleError(error)}`);
        }
    }

    const getCompartmentsOfUser = async() => {
        try{
            await api.get('/api/accesses/compartments/' + localStorage.getItem('token'))
                .then(x => x.data)
                .then(y => {
                    console.log("y")
                    console.log(y)
                    setCompartmentsOfUser({data:y, loading:false});
                });
            } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getCompartmentsOfUser): \n${handleError(error)}`);
        }
    }

    const getLockerId = async(compartmentUUID) => {
        try {
            await api.get("/api/lockers/" + compartmentUUID + "/lockerId")
                .then(x => x.data)
                .then(y => {
                    setLockerId({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getLockerId): \n${handleError(error)}`);
        }
    }

    const getAllCompartments = async () => {
        try {
            const response = await api.get('/api/lockers/' + lockerId.data + '/compartments')
            setCompartments({data:response.data, loading:true})
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during getAllCompartments request: \n${handleError(error)}`);
        }
    }

    function exctractToken(){
        localStorage.setItem('token', token);
    }

    useEffect(() => {
        userDoesExist()
    }, []);

    useEffect(() => {
        if(!userExists.loading){
            exctractToken()
            getCompartmentsOfUser()
        }
    }, [userExists]);

    useEffect(() => {
        if(!compartmentsOfUser.loading){
            getLockerId(compartmentsOfUser.data[0].compartmentUUID)
        }
    }, [compartmentsOfUser]);

    useEffect(() => {
        if(!lockerId.loading){
            getAllCompartments()
        }
    }, [lockerId]);


    return (
        <div>
            <Header/>
            {userExists && <p style={{marginLeft: '7%'}}> Please scan the shared compartment to unlock.</p>}
            {!compartmentsOfUser.loading &&
            <OverviewLockerUI
                style={{marginTop: 0}} columns={2}
                compartments={compartments.data}
                compartmentsOfUser={compartmentsOfUser}
                scanningRequired={true}
                shared ={true}
                requiresVerification={phonenumber}
            />}
        </div>
    );
}