import React, { useState, useEffect } from 'react';
import {api, handleError} from "../../helpers/api";
import '@ionic/react/css/ionic.bundle.css';
import Header from '../../views/Header';
import {IonButton} from '@ionic/react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import "../../views/stylesheet.css"
import {Button} from "../../views/designElements";

export default function BookingPage(){
    const location = useLocation();
    let history = useHistory();
    const lockerId = location.lockerId;
    const [ locationKeys, setLocationKeys ] = useState([])
    const compartmentUUID = location.compartmentUUID;
    const [compartmentName, setCompartmentName] = useState(null)
    const [requestBody,setBody] = useState();


    function redirectDepending() {
        if (location.comingFromOverview) {
            history.push({
                pathname: '/overview/'+lockerId,
                lockerId: lockerId
            });
        }
        else {
            history.push('/scanner');
        }
    }

    async function openCompartment(){
        try {
            const response = await api.put('/api/accesses/' + compartmentUUID +'/open', requestBody)
            localStorage.setItem('token', response.data)
            history.push({
                pathname:'/information',
                lockerId: lockerId,
                compartmentUUID: compartmentUUID,
                compartmentName: compartmentName,
                successfull: true
            })

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during put request: \n${handleError(error)}`);
        }
    }

    function getCompartmentByCompartmentUUID(){
        try {
            const f = async () => {
                return  await api.get('/api/compartments/'+ compartmentUUID)
            }

            f()
                .then(x => x.data)
                //.then(x => x.text()
                .then(y => {
                    setCompartmentName(y.compartmentName)
                });

        } catch (error) {
            alert(`Something went wrong during GET request (getCompartmentByCompartmentUUID): \n${handleError(error)}`);
        }
    }

    useEffect(() => {
        getCompartmentByCompartmentUUID()
        if (localStorage.getItem('token')){
            setBody(JSON.stringify({
                    token: localStorage.getItem('token')
                }));
        }
    }, []);

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
      
              history.push({
                pathname:'/home',
            })
      
      
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
      
              history.push({
                pathname:'/home',
            })
      
            }
          }
        })
      }, [ locationKeys, ])

    return (
        <div>
            <Header/>
            {<p style={{marginLeft: "10%"}}>You successfully acquired compartment {compartmentName}. Are you happy with this choice? If you'd like to choose another compartment press "Cancel". </p>}
            <div style={{display: "flex"}}>
                <Button style={{marginLeft: '9%', marginRight: '5%'}} onClick={ () => openCompartment() }>Open </Button>
                <Button color={"#E5E5E5"} onClick={ () => redirectDepending() }>Cancel </Button>
            </div>
        </div>
    );
}