import React, {useEffect, useState} from 'react';
import {api, handleError} from "../helpers/api";
import '@ionic/react/css/ionic.bundle.css';
import Spinner from "../views/Spinner";
import {useHistory, useLocation} from 'react-router';
import { useParams } from 'react-router-dom';
import Header from '../views/Header';
import Verification from "./Verification";


// handles all urls of format: http://localhost:3000/compartments/{compartmentUUID}/open
function QR() {
    let history = useHistory();
    let location = useLocation();
    const [lockerId, setLockerId] = useState({data:null, loading:true});
    const {compartmentUUID} = useParams();
    const [occupied, setOccupied] = useState();
    const [lockerRequiresValidation, setLockerRequiresValidation] = useState({data:null, loading:true});
    const [userIsValidated, setUserIsValidated] = useState({data:null, loading:true});
    const [verificationModalIsOpen, setVerificationModalIsOpen] = useState(location.requiresVerification);

    // GET request to get lockerId
    const getLockerId = async(compartmentUUID) => {
        try {
            return  await api.get("/api/lockers/" + compartmentUUID + "/lockerId")
                .then(x => x.data)
                .then(y => {
                    setLockerId({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getLockerId): \n${handleError(error)}`);
        }
    }
    
    // GET request to check if the compartment is occupied (if true == occupied)
    async function isCompartmentOccupied(){
        try {
            // "/accesses/{compartmentUUID}/occupied"
            const response = await api.get("/api/accesses/" + compartmentUUID + "/occupied")
            // GET return true if occupied, so isFree == false in this case
            // occupied whether compartment is free (if not occupied if all compartments are being used)
            setOccupied(response.data);
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (compartmentIsOccupied): \n${handleError(error)}`);
        }
    }

    // GET request to check if token of the user is identical to the token of the user who occupies the compartment
    async function hasAccessToCompartment(){
        try {
            // "/accesses/{compartmentUUID}/{token}"
            const response = await api.get('/api/accesses/' + compartmentUUID + '/' + localStorage.getItem('token'))
            return response.data
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (hasAccessToCompartment): \n${handleError(error)}`);
        }
    }

    // GET request to check if locker requires phone validation
    const lockerRequiresPhoneValidation = async() => {
        try {
             await api.get("/api/lockers/" + lockerId.data + "/requiresValidation")
                .then(x => x.data)
                .then(y => {
                    setLockerRequiresValidation({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (lockerRequiresPhoneValidaton): \n${handleError(error)}`);
        }
    }

    // GET request to check if user is verified
    const userIsVerified = async() => {
        try {
            if(!localStorage.getItem("token")){
                return false;
            }
            return await api.get("/api/users/" + localStorage.getItem("token") + "/isValidated")
                .then(x => x.data)
                .then(y => {
                    setUserIsValidated({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (userIsVerified): \n${handleError(error)}`);
        }
    }

    let redirectToBooking = () => {
        history.push({
            pathname:'/booking',
            lockerId: lockerId.data,
            compartmentUUID: compartmentUUID,
            comingFromOverview: location.comingFromOverview
        })
    }

    let redirectToUnlock = () => {
        history.push({
            pathname: '/unlock',
            lockerId: lockerId.data,
            compartmentUUID: compartmentUUID,
        })
    }

    let redirectToOverview = (occupied, hasCompartments) => {
        history.push({
            pathname: '/overview/'+lockerId,
            lockerId: lockerId.data,
            compartmentUUID: compartmentUUID,
            occupied: occupied,
            hasCompartments: hasCompartments,
        })
    }

    useEffect(() => {
        console.log("location")
        console.log(location)
        if(location.requiresVerification){
            setVerificationModalIsOpen(true)
        }
        isCompartmentOccupied()
        getLockerId(compartmentUUID)
        userIsVerified()
    }, [])

    useEffect(() => {
        if(!location.rerender){return}
        isCompartmentOccupied()
        getLockerId(compartmentUUID)
        userIsVerified()
    }, [location])

    useEffect(() => {
        if(lockerId.loading){
            return
        }
        lockerRequiresPhoneValidation()
    }, [lockerId])

    useEffect(() => {
        if(lockerRequiresValidation.loading){return}
        if(occupied==undefined){return}

        //if locker requires validation
        if(lockerRequiresValidation.data){
            //if user is verified
            if(userIsValidated.data){
                //if compartment is occupied
                if(occupied){
                    console.log("occupied")
                    if (localStorage.getItem('token')){
                        console.log("has token")
                        hasAccessToCompartment().then(response => {
                            console.log("location.requiresVerification")
                            console.log(location.requiresVerification)
                            if(!location.requiresVerification){
                                if(response){redirectToUnlock()}
                                else {redirectToOverview(true, true)}
                            }
                        })
                    }
                    else {
                        redirectToOverview(true, false)
                    }
                }
                //if compartment is not occupied
                else {
                    if (localStorage.getItem('token')){
                        if(location.comingFromOverview){
                            redirectToBooking()
                        }
                        else {
                            redirectToOverview(false, true)
                        }
                    }
                    else {
                        redirectToBooking()
                    }
                }
            }
        }

        // if locker does not require validation
        else if(!lockerRequiresValidation.data ){
            console.log("locker does not require validation")
            if(occupied){
                if (localStorage.getItem('token')){
                    console.log("1")
                    hasAccessToCompartment().then(response => {
                        console.log("2")
                        if(!location.requiresVerification){
                            console.log("3")
                            console.log(response)
                            if(response){redirectToUnlock()}
                            else {redirectToOverview(true, true)}
                        }
                    })
                }
                else {
                    redirectToOverview(true, false)
                }
            }
            //not occupied
            else {
                if (localStorage.getItem('token')){
                    redirectToBooking()
                }
                else {
                    redirectToBooking()
                }
            }
        }

        //if user is not verified and locker requires validation
        setVerificationModalIsOpen(true)

      }, [lockerRequiresValidation, occupied]);



    return (
        <div>
            <Header/>
            <Verification open={verificationModalIsOpen} compartmentUUID={compartmentUUID} lockerId={lockerId} phoneNumber={location.requiresVerification}/>
            <p> Successfully scanned the QR Code!</p>
            <p> Loading...</p>
            <p> {userIsValidated.data ? "user is verified" : "Please validate yourself"}</p>
            <Spinner/>
        </div>
    );
}

export default QR;