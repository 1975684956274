import styled from "styled-components";

export const Button = styled.button`
    background: ${props => props.color ? props.color : "#1C768F"};
    box-shadow: ${props => (props.disabled ? "" : "0px 4px 4px rgba(0, 0, 0, 0.25)")};
    border-radius: 25px;
    width: 180px;
    height: 65px;
    color: ${props => props.color=="#E5E5E5" ? "black" : "white"};
    font-size: 17px;
    opacity: ${props => (props.disabled ? 0.4 : 1)};
    margin: 1%; 
    &:hover {
        transform: translateY(-2px);
        cursor: ${props => props.disabled ? "default" : "pointer"};
    }
`;

export const InputField = styled.input`
  &::placeholder {
    color: grey;
  }
  width: 100%; 
  margin: 0%;
  height: 35px;
  border: none;
  background: #E5E5E5;
  border-radius: 15px;
  align-items: center;
  padding-left: 3%;
`;


export const ColumnDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const TextLabel = styled.p`
   padding-right: 15px; 
   align-items: center;
`;

export const Label = styled.label`
  margin-left: 7%;
`;