import React from 'react';
import './App.css';
import '@ionic/react/css/ionic.bundle.css';
import {IonApp, IonContent} from '@ionic/react';
import AppRouter from "./helpers/routing/AppRouter";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from "react-alert-template-basic";

// optional configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 7000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
}

const CustomAlertTemplate = (props) => {
    return <AlertTemplate {...props} style={{backgroundColor: '#c9c9c9', width: '80%', margin: 'auto'}}/> //<---- customColor sets here
}

function App() {
  return (
      <AlertProvider template={CustomAlertTemplate} {...options}>
        <IonApp>
          <IonContent>
              <AppRouter/>
          </IonContent>
        </IonApp>
      </AlertProvider>
  );
}

export default App;