import React from "react";
import Header from "../../views/Header";
import "../../views/stylesheet.css"
import {useHistory} from "react-router";

export default function Admin(){
    const history = useHistory()

    return (
        <div>
            <Header/>
            <button className="button" onClick={() => history.push("/admin/chooseModel")}> Register a new Locker</button>
        </div>
    );
}