import React, { useEffect, useState } from "react";
import Header from "../views/Header";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {api, handleError} from "../helpers/api";
import {Button} from "../views/designElements";

export default function Unlock(){
    const history = useHistory();
    const location = useLocation();
    const [ locationKeys, setLocationKeys ] = useState([])
    const compartmentUUID = location.compartmentUUID
    const [opened, setOpened] = useState(false)
    const [timeUp, setTimeUp] = useState(false)
    const [hasCompartments, setHasCompartments] = useState({"data": null, "loading":true})
    const [compartment, setCompartment] = useState({"data": null, "loading":true})

    //PUT
    async function openCompartment(){
        "/accesses/{compartmentUUID}/open"
        try {
            const requestBody = JSON.stringify({
                token: localStorage.getItem('token')
            });

            await api.put('/api/accesses/' + compartmentUUID + '/open', requestBody);
            setOpened(true);

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during PUT request: \n${handleError(error)}`);
        }
    }

    const getHasCompartments = async() => {
        try {
            return await api.get('/api/accesses/' + 1 + '/compartments/' + localStorage.getItem('token'))
                .then(x => x.data)
                .then(y => {
                    let dict = {};
                    setHasCompartments({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (hasCompartments): \n${handleError(error)}`);
        }
    }

    const getCompartment = async() => {
        try {
            return  await api.get('/api/lockers/compartments/'+ compartmentUUID)
                .then(x => x.data)
                .then(y => {
                    setCompartment({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getCompartment): \n${handleError(error)}`);
        }
    }

    const sendEmail = async() => {
        try {
            return  await api.get('/api/users/email/'+ compartmentUUID)
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (hasCompartments): \n${handleError(error)}`);
        }
    }

    //unlocks scanned compartment
    useEffect(() => {
        openCompartment()
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTimeUp(true);
        },120000);
        return () => clearTimeout(timeout);
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            getCompartment();
            if (compartment.data.doorStatus == "OPEN"){
                sendEmail();
            }
        },240000);
        return () => clearTimeout(timeout);
    }, [])

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
      
              history.push({
                pathname:'/home',
            })
      
      
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
      
              history.push({
                pathname:'/home',
            })
      
            }
          }
        })
      }, [ locationKeys, ])

    //checks whether user has any more compartments after unlocking
    useEffect(() => {
        getHasCompartments()
    }, [opened])

    //checks the doorStatus every few seconds
    useEffect(() => {
        const interval = setInterval(() => {
            getCompartment()
        }, 3000);
        if(!compartment.loading){
            if(compartment.data.doorStatus == "CLOSED"){
                clearInterval(interval)
            }
        }
        return () => clearInterval(interval)
    }, [opened])

    //deletes token if user has no more compartments
    /*useEffect(() => {
        if(!hasCompartments.loading){
            if(opened){
                console.log("USER RE-OPENED")
                if (localStorage.getItem('token')){
                    console.log("USER HAS A TOKEN")
                    if (!hasCompartments.data){
                        console.log("USER DOES NOT HAVE ANY COMPARTMENTS")
                        localStorage.removeItem('token');
                    }
                }
            }
        }
    }, [hasCompartments])*/


    function redirectToScanningPage() {
        history.push('/scanner');
    }

    function redirectToHome() {
        history.push('/home');
    }


    return(
        <div style={{marginLeft: '7%'}}>
            <Header/>
            {compartment.loading ? <p>Thank you for using CloudLock! <br/> Opening the door ... </p> : null}
            {compartment.loading ? null : (compartment.data.doorStatus == "OPEN" && <p>Thank you for using CloudLock! <br/>Please remember to close the compartment door once you have removed all of your items.</p>)}
            {timeUp && (compartment.loading ? null : (compartment.data.doorStatus == "OPEN" && <p colour='red'>Please lock the door!</p>))}
            {compartment.loading ? null : (compartment.data.doorStatus == "CLOSED"  && <p>To acquire a new compartment scan any Qr code.</p>)}
            {compartment.loading ? null : (compartment.data.doorStatus == "CLOSED" && <Button style={{marginRight: '15px'}} onClick={ () => redirectToScanningPage()}>Scan QR Code </Button>)}
            {compartment.loading ? null : (compartment.data.doorStatus == "CLOSED" && <Button onClick={ () => redirectToHome()}>Home </Button>)}
        </div>


    )

}