import React, {useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import {api, handleError} from "../api";

/**
 * routeProtectors interfaces can tell the router whether or not it should allow navigation to a requested route.
 * They are functional components. Based on the props passed, a route gets rendered.
 * @Guard
 * @param props
 */
export const AdminGuard = props => {
    const [render, setRender] = useState(null)

    function isAdmin(){
        try {
            const f = async () => {
                const response = await api.get("/api/users/"+localStorage.getItem("token")+"/admin")
                return response
            }

            f()
                .then(x => x.data)
                //.then(x => x.text()
                .then(y => {
                    setRender(y ? props.children : <Redirect to={"/home"} />)
                });

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (isAdmin): \n${handleError(error)}`);
        }
    }

    useEffect(() => {
        isAdmin()
    }, [])

    return(render)
};
