import React, {useEffect} from 'react';
import {Button} from "../designElements";
import {api, handleError} from "../../helpers/api";

const ShareButton = (props) => {

    const share = async() =>{
        try {
            await api.post('/api/accesses/share/' + props.compartmentUUID)
                .then(x => x.data)
                .then(y => {
                    console.log("here"+y)
                    if (navigator.share) {
                        navigator.share({
                            title: "You have now access to a compartment",
                            text: "Please open this link to open the shared compartment",
                            url: "share/"+y,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    } else {
                        console.log("Web Share API is not supported in your browser.")
                    }});
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getSharedToken): \n${handleError(error)}`);
        }
    }

    return (
        <Button style={props.style}
                onClick={share}>
            <i className="fas fa-share" style={{color: "white", fontSize: "1em"}}/>
        </Button>
    );
};

export default ShareButton;

