import { isProduction } from './isProduction';

/**
 * This helper function returns the current domain of the API.
 * If the environment is production, the production Heroku URL will be returned.
 * Otherwise, the link localhost:8080 will be returned (Spring server default port).
 * @returns {string}
 */
export const getDomain = () => {
    const prodUrl = 'https://cloudlock.esgcloud.ch';
    const devUrl = 'https://cloudlock.esgcloud.ch';
    // const prodUrl = 'http://195.150.195.153:9101';
    // const devUrl = 'http://195.150.195.153:9101';
    // const devUrl = 'http://localhost:9101';
    // const prodUrl = 'http://localhost:9101';

    return isProduction() ? prodUrl : devUrl;
};
