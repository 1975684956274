import {Button, ColumnDiv, InputField, TextLabel} from "../designElements";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {api, handleError} from "../../helpers/api";
import {useAlert} from "react-alert";
import "../stylesheet.css"
import XRegExp from "xregexp";
import ShareButton from "./ShareButton";

export default function ShareOverlay(props){
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [compartmentsOfUser, setCompartmentsOfUser] = useState([])
    const [compartments, setCompartments] = useState([])
    const [selectedCompartment, setSelectedCompartment] = useState(null)
    const [disableSelect, setSelectDisabled] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("0")
    const [phoneCheck, setPhoneCheck] = useState(false)
    const popupAlert = useAlert();
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            verticalAlign: 'middle'
        },
    };

    Modal.setAppElement('#root');

    async function sendShareSMS(){
        try {
            if(!selectedCompartment){
                return  await api.post('/api/accesses/share/' + compartmentsOfUser[0].compartmentUUID, phoneNumber)
            }
            else{
                return  await api.post('/api/accesses/share/' + selectedCompartment, phoneNumber)
            }
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during POST request (sendShareSMS): \n${handleError(error)}`);
        }
    }

    function validateSwissNumber(phoneNumber){
        phoneNumber = phoneNumber.split(' ').join('');
        var regex;
        if (phoneNumber.charAt(0) == '+'){
            regex = XRegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/);
        }
        else {
            regex = XRegExp(/^\d{10}$/);
        }
        var matches = XRegExp.match(phoneNumber, regex);
        if (!matches){
            return;
        }
        setPhoneCheck(true);
    }

    function invalidateSwissNumber(phoneNumber){
        setPhoneNumber(phoneNumber.split(' ').join(''))
        var regex;
        if (phoneNumber.charAt(0) == '+'){
            regex = XRegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/);
        }
        else {
            regex = XRegExp(/^\d{10}$/);
        }
        var matches = XRegExp.match(phoneNumber, regex);
        if (matches){
            return;
        }
        setPhoneCheck(false);
    }

    useEffect(() => {
        setModalIsOpen(props.open)
        setCompartmentsOfUser(props.compartmentsOfUser)
        setCompartments(props.compartments)
        if(!props.compartmentUUID){return}
        setSelectedCompartment(props.compartmentUUID)
    }, [props])

    /*useEffect(() => {
        if(props.compartmentUUID && selectedCompartment){
            getSharedToken()
        }
    }, [selectedCompartment])*/

    useEffect(() => {
        validateSwissNumber(phoneNumber)
        invalidateSwissNumber(phoneNumber)
    }, [phoneNumber])


    function handlePhoneNumber(){
        sendShareSMS()
        popupAlert.show("sent message to"+ phoneNumber)
        setTimeout(function(){
                setModalIsOpen(false);
                setSelectDisabled(false)
                setPhoneNumber("0")},
            1750);
    }


    return(
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            contentLabel="ShareOverlay Modal">
            {<div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridGap: '10px', gridAutoRows: 'min-content', alignItems: 'center'}}>
                <TextLabel style={{gridColumn: '1 / 4', gridRow: 1}}> <b>
                    Share access to {props.compartmentName ? "your compartment "+props.compartmentName : "one of your compartments"}</b>
                </TextLabel>
                {/*if user has several compartments --> select to choose from compartments*/}
                {props.compartmentsOfUser &&
                <select style={{gridColumn: '1 / 3', gridRow: 2, marginBottom: '20px'}}
                        disabled={disableSelect}
                        onChange={(e)=>{setSelectedCompartment(e.target.value)}}>
                    {compartmentsOfUser.map((compartment) =>
                    compartments.some(item => item.compartmentUUID === compartment.compartmentUUID) ?
                            <option key={compartment.compartmentId} label={compartment.compartmentName} value={compartment.compartmentUUID}/> :  null)}
                </select>}
                {/*Share Button & Phone Number*/}
                {<p style={{margin: '0%', gridColumn: '1 / 5', gridRow: 3}}> Share access via phone number or link </p>}
                {<ColumnDiv style={{gridColumn: '1 / 3', gridRow: 4}}>
                    <InputField
                        style={{marginRight: '5%'}}
                        key='input1'
                        maxLength="18"
                        placeholder={"+41 76 123 45 67"}
                        onChange={e => {
                            setPhoneNumber(e.target.value)}}
                    />
                    <Button disabled={!phoneCheck} onClick={handlePhoneNumber} style={{width: '80px', height: '30px'}}> OK </Button>
                </ColumnDiv>}
                {<ShareButton
                    compartmentUUID={selectedCompartment ? selectedCompartment : (compartmentsOfUser[0] ? compartmentsOfUser[0].compartmentUUID: null)}
                    style={{gridColumn: '3', gridRow: 4, width: '70px', height: '30px'}}
                />}
            </div>}
        </Modal>
    )
}