import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Header from "../views/Header";
import "../views/stylesheet.css"
import {api, handleError} from "../helpers/api";
import {Button} from "../views/designElements";

export default function Home(){
    let history = useHistory();
    const [lockers, setLockers] = useState([])
    
    function redirectToScanningPage() {
        history.push('/scanner');
    }

    function redirectToOverviewPage(lockerId) {
        history.push({
            pathname: '/overview/'+lockerId,
            scanningRequired: true
        });
    }

    async function userDoesExist() {
        try {
            if(localStorage.getItem('token')){
                const response = await api.get('/api/users/exists/'+localStorage.getItem('token'))
                if(!response.data){
                    console.log("remove Token first")
                    //localStorage.removeItem('token')
                }
            }

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during userDoesExist request: \n${handleError(error)}`);
        }
    }

    async function getLockers() {
        try {
            const response = await api.get('/api/lockers')
            setLockers(response.data)

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during getLockers GET request: \n${handleError(error)}`);
        }
    }


    useEffect(() => {
        userDoesExist()
        getLockers()
    }, []);

    return (
        <div style={{textAlign: "center"}}>
            <Header/>
            <p>To acquire a compartment scan the QR code of a compartment</p>
            <Button style={{display: "block", margin: "auto", marginBottom: "45px", marginTop: "30px"}} onClick={ () => redirectToScanningPage()}>Scan QR Code </Button>
            {lockers.map((locker) => <Button style={{display: "block", margin: "auto", marginTop: "20px"}} onClick={ () => redirectToOverviewPage(locker.lockerId)} key={locker.lockerId}>View Locker {locker.lockerId} </Button>)}
            {/*<ShareOverlay open={true} compartmentUUID={"00003456-e89b-12d3-a456-426614174000"} compartmentName={"tste"}/>*/}
        </div>
    );
}