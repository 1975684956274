import React, {useEffect, useState} from 'react';
import '@ionic/react/css/ionic.bundle.css';
import OverviewLockerUI from "../../views/LockerUIs/OverviewLockerUI";
import {api, handleError} from "../../helpers/api";
import { useLocation, useHistory } from "react-router";
import {useAlert} from "react-alert";
import Header from "../../views/Header";
import {Button} from "../../views/designElements";
import ShareOverlay from "../../views/Share/ShareOverlay"
import {useParams} from "react-router-dom";

export default function Overview() {
    let history = useHistory();
    const [ locationKeys, setLocationKeys ] = useState([])
    const [compartments, setCompartments] = useState([]);
    const [compartmentsOfUser, setCompartmentsOfUser] = useState({"data": [], "loading":true})
    const [locker, setLocker] = useState({"data": null, "loading":true})
    const location = useLocation();
    const {lockerId} = useParams();
    const [scanningRequired, setScanningRequired] = useState(location.scanningRequired)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [count, setCount] = useState(0)
    const popupAlert = useAlert();

    async function getAllCompartments() {
        try {
            const response = await api.get('/api/lockers/' + lockerId + '/compartments')

            const array = []
            let i;
            for (i = 0; i < response.data.length; i++) {
                array.push(response.data[i]);
            }

            setCompartments(array)

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during getAllCompartmentsByLockerId request: \n${handleError(error)}`);
        }
    }

    const getCompartmentsOfUser =async() => {
        try {
            return  await api.get('/api/accesses/compartments/' + localStorage.getItem('token'))
                .then(x => x.data)
                .then(y => {
                    //returns a list with compartments
                    const arr = []
                    for (let i = 0; i < y.length; i++) {
                        arr.push(y.compartmentUUID)
                    }
                    setCompartmentsOfUser({data:y, loading:false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getCompartmentsOfUser): \n${handleError(error)}`);
        }
    }

    const getLocker = async() => {
        try {
            const id = lockerId ? lockerId : 1
            await api.get('/api/lockers/' + id)
                .then(x => x.data)
                .then(y => {
                    setLocker({data: y, loading: false});
                });
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getLocker): \n${handleError(error)}`);
        }
    }

    // this will run, when the component is first initialized
    useEffect(() => {
        console.log(lockerId.lockerId)
        console.log("lockerId")
        getLocker()
        getAllCompartments();
        if(localStorage.getItem('token')){
            getCompartmentsOfUser();
        }
        if(location.showAlert){
            popupAlert.show("You did not scan the selected compartment, please try again", { type: "info" })
            setScanningRequired(true)
        }
    }, []);

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
      
              history.push({
                pathname:'/home',
            })
      
      
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
      
              history.push({
                pathname:'/home',
            })
      
            }
          }
        })
      }, [ locationKeys, ])

    return (
        <div>
            <Header/>
            <div style={{marginLeft:"7%", marginTop:"2%"}}>
                <p style={{fontSize: "20px", fontWeight:"bold", marginBottom: "3%", color:"#1c2149"}}>{locker.loading ? null : locker.data.name}
                    <span style={{fontSize: "20px"}}>{locker.loading ? null : "  ("+locker.data.location+")"}</span></p>
                {location.occupied ? (location.hasCompartments ? "Sorry, this compartment is occupied, select another one or unlock your compartment": "Sorry, this compartment is occupied. Tap on a free compartment to select a different one."):"Select a free compartment or unlock your compartments"}
            </div>
            <OverviewLockerUI
                columns={2}
                compartments={compartments}
                compartmentsOfUser={compartmentsOfUser}
                scanningRequired={scanningRequired}
            />
            {compartments && !compartmentsOfUser.loading && compartments.some(comp => compartmentsOfUser.data.some(comp2 => comp2.compartmentId == comp.compartmentId)) &&
            <Button style={{marginLeft:"10%", marginBottom: "7%"}} onClick={() => {setModalIsOpen(true); setCount(prevState => prevState+1)}}>Share</Button>}
            <ShareOverlay open={modalIsOpen} compartmentsOfUser={compartmentsOfUser.data} compartments={compartments} rerender={count}/>
        </div>
    );
}