import React, { useEffect, useState } from "react";
import {Button, ColumnDiv, InputField} from "../views/designElements"
import Modal from "react-modal";
import {api, handleError} from "../helpers/api";
import { useAlert } from 'react-alert'
import {useHistory} from "react-router";
import XRegExp from 'xregexp';

export default function Verification(props) {
    const alertPopUp = useAlert();
    const history = useHistory();
    const [firstModalIsOpen, setFirstModalIsOpen] = useState(false);
    const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);
    const [phoneNumberBackEnd, setphoneNumberBackEnd] = useState();
    const [phoneNumber, setPhoneNumber] = useState("0");
    const [code, setCode] = useState(null);
    const [verificationCode, setVerificationCode] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [phoneCheck, setPhoneCheck] = useState(false);
    const [token, setToken] = useState(null);

    function compareCodes(userInput, verificationCode){
        return userInput == verificationCode;
    }

    function validateSwissNumber(phoneNumber){
        phoneNumber = phoneNumber.split(' ').join('');
        var regex;
        if (phoneNumber.charAt(0) == '+'){
            regex = XRegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/);
        }
        else {
            regex = XRegExp(/^\d{10}$/);
        }
        var matches = XRegExp.match(phoneNumber, regex);
        if (!matches){
            return;
        }
        console.log("PHONENUMBER")
        console.log(phoneNumber)
        console.log("PHONENUMBER BACKEND")
        console.log(phoneNumberBackEnd)
        if (token){
            if (phoneNumberBackEnd){
                if (phoneNumber != phoneNumberBackEnd){
                    return;
                }
            }
        }
        setPhoneCheck(true);
    }

    function invalidateSwissNumber(phoneNumber){
        setPhoneNumber(phoneNumber.split(' ').join(''))
        var regex;
        if (phoneNumber.charAt(0) == '+'){
            regex = XRegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/);
        }
        else {
            regex = XRegExp(/^\d{10}$/);
        }
        var matches = XRegExp.match(phoneNumber, regex);
        if (token){
            if (matches && phoneNumber == phoneNumberBackEnd){
                return
            }
        }
        else if (matches){
            return;
        }
        setPhoneCheck(false);
    }

    const retrievePhoneNumberBackEnd = async() => {
        if (phoneNumber == "0"){
            return
        }
        try{
            const response = await api.get("api/users/phone/"+localStorage.getItem('token'))
            setphoneNumberBackEnd(response.data)
        } catch (error) {
            alert(`Something went wrong during GET request (verifyPhoneNumber): \n${handleError(error)}`);
        }
    }

    const verifyPhoneNumber = async() => {
        setFirstModalIsOpen(false)
        setSecondModalIsOpen(true)
        //Verify phone number
        try{
            const requestBody = JSON.stringify({
                token: localStorage.getItem('token'),
                phoneNumber: phoneNumber
            })
            return  await api.put("api/users/phone", requestBody)
                .then(x => x.data)
                .then(y => {
                // TODO: remove for production
                    console.log("Verification code: " + y[1])
                    setVerificationCode({data:y[1], loading:false});
                    localStorage.setItem('token', y[0])
                    });
        } catch (error) {
            alert(`Something went wrong during PUT request (verifyPhoneNumber): \n${handleError(error)}`);
        }
    }


    async function verifyCode(){
        setSecondModalIsOpen(false)
        //Verify code
        if(!verificationCode){return}
        if (!compareCodes(verificationCode.data, code)){
            alertPopUp.show("The code you entered was wrong. Please retry.")
            setFirstModalIsOpen(true)
        }
        else {
            try {
                const response = await api.put("api/users/"+phoneNumber+"/validated");
                localStorage.setItem('token', response.data)
                setRedirect(response.data)
            } catch (error) {
                console.log(error);
                alert(`Something went wrong during PUT request (verifyCode): \n${handleError(error)}`);
            }
        }
    }

    useEffect(() => {
        if (!redirect) {return}
        history.push({
            pathname: '/compartments/'+props.compartmentUUID+'/open',
            comingFromOverview: true,
            rerender: true
        })
    }, [redirect])



    Modal.setAppElement('#root');

    const customStyles = {
        background: 'red',
        width: '90vw',
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        setFirstModalIsOpen(props.open)
    }, [props])

    useEffect(() => {
        //retrievePhoneNumberBackEnd()
        validateSwissNumber(phoneNumber)
        invalidateSwissNumber(phoneNumber)
    }, [phoneNumber])

    useEffect(() => {
        setToken(localStorage.getItem('token'))
    },[])

    useEffect(() => {
        if (token){
        retrievePhoneNumberBackEnd()
        }
    })

    return(
        <div>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={firstModalIsOpen}
                onRequestClose={() => {setFirstModalIsOpen(false); setSecondModalIsOpen(true)}}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p style={{margin: '0%', marginBottom: '4%'}}> Please enter your phone number: </p>
                <ColumnDiv>
                    <InputField
                        style={{marginRight: '3%'}}
                        key='input1'
                        maxLength="18"
                        placeholder={"+41 76 123 45 67"}
                        onChange={e => {
                            setPhoneNumber(e.target.value)}}
                    />
                    <Button disabled={!phoneCheck} style={{width: '80px', height: '30px'}} onClick={verifyPhoneNumber}> OK </Button>
                </ColumnDiv>
            </Modal>
            <Modal
                isOpen={secondModalIsOpen}
                onRequestClose={() => setSecondModalIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p style={{margin: '0%', marginBottom: '4%'}}> Please enter the code you received: </p>
                <ColumnDiv>
                    <InputField
                        style={{marginRight: '3%'}}
                        key='input1'
                        maxLength="6"
                        placeholder={"******"}
                        type={"password"}
                        onChange={e => {setCode(e.target.value)}}
                    />
                    <Button style={{width: '80px', height: '30px'}} onClick={verifyCode}> OK </Button>
                </ColumnDiv>
            </Modal>
        </div>
    )
}