import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Admin from "../../components/Admin/Admin"
import ChooseModel from "../../components/Admin/ChooseModel";
import {Redirect} from "react-router";
import Scan from "../../components/Admin/Scan";
import SetUp from "../../components/Admin/SetUp";

class AppRouter extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to={"/home"} />} />
                    <Route
                        path="/admin" exact
                        render={() => (
                            <Admin/>
                        )}
                    />
                    <Route
                        path="/admin/choosemodel"
                        render={() => (
                            <ChooseModel/>
                        )}
                    />
                    <Route
                        path="/admin/scan"
                        render={() => (
                            <Scan/>
                        )}
                    />
                    <Route
                        path="/admin/setup"
                        render={() => (
                            <SetUp/>
                        )}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default AppRouter;