import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../../components/Home";
import QR from "../../components/QR";
import Overview from "../../components/Acquiring/Overview";
import Scanner from "../../components/Scanner";
import BookingPage from "../../components/Acquiring/BookingPage";
import Information from "../../components/Acquiring/Information";
import Unlock from "../../components/Unlock";
import {AdminGuard} from "./AdminGuard"
import AdminRouter from "./AdminRouter"
import {Redirect} from "react-router";
import Shared from "../../components/Shared";

class AppRouter extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to={"/home"} />} />
                    <Route
                        path="/home" exact
                        render={() => (
                            <Home/>
                         )}
                    />
                    <Route
                        path="/overview/:lockerId"
                          render={() => (
                          <Overview/>
                         )}
                        />
                    <Route
                        path="/compartments/:compartmentUUID/open"
                        render={() => (
                            <QR/>
                        )}
                    />
                    <Route
                        path="/scanner"
                        render={() => (
                            <Scanner/>
                        )}
                    />
                    <Route
                        path="/booking"
                        render={() => (
                            <BookingPage/>
                        )}
                    />
                    <Route
                        path="/information"
                        render={() => (
                            <Information/>
                        )}
                    />
                    <Route
                        path="/unlock"
                        render={() => (
                            <Unlock/>
                        )}
                    />
                    <Route
                        path="/admin"
                        render={() => (
                            <AdminGuard>
                                <AdminRouter/>
                            </AdminGuard>
                        )}
                    />
                    <Route
                        path="/share/:token" exact
                        render={() => (
                            <Shared/>
                        )}
                    />
                    <Route
                        path="/share/:token/:phonenumber" exact
                        render={() => (
                            <Shared/>
                        )}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default AppRouter;
