import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";


export default function Spinner(){
        return (
            <Loader
                type="Oval"
                color="#1C768F"
                height={100}
                width={100}
                marginLeft="10%"
                //timeout={3000} //3 secs
            />
        );
}