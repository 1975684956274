import React, {useEffect} from "react";
import Header from "../../views/Header";
import "../../views/stylesheet.css"
import {useLocation} from "react-router";
import ScanLockerUI from "../../views/LockerUIs/ScanLockerUI";

export default function Scan(props){
    let location = useLocation()

    useEffect(()=>{
    })

    return (
        <div>
            <Header/>
            <ScanLockerUI columns={location.columns} NumberOfCompartments={location.NumberOfCompartments} imei={location.imei}/>
        </div>
    );
}