import React, { useState } from "react";
import "../stylesheet.css"
import styled from "styled-components";
import { useHistory } from "react-router";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { api, handleError } from "../../helpers/api";
import { Button, InputField, ColumnDiv, TextLabel, Label} from "../designElements";
import Modal from 'react-modal';

const CompartmentContainer = styled.div`
    /* Demo-Specific Styles backgroundColor: #FA991C;*/
    background-color: ${props => props.finished ? "#7C9473" : (props.selected ? "#FA991C" : (props.allFinished ? "#7C9473" : "#EEEEEE"))};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 75%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 5px;
    border-radius: 20px;

    /* Flex Fallback */
    @supports (display: block) {
    margin: 0;
    color: white;
    }
`;

const LockerContainer = styled.div`
    /* Grid Fallback */
    display: flex;
    flex-wrap: wrap;

    /* Supports Grid */
    display: grid;
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 1.5em;
    background: #E5E5E5;
    border-radius: 18px;
    margin: 7%;
    padding: 3%;
    display: grid;
    grid-template-columns: ${props => props.columns ? "auto ".repeat(props.columns) : "auto auto"};
`;

export const BigContainer = styled.div`
  width: 75%;
  height: 150px;
  margin-left: 7%;
`;

export default function ScanLockerUI(props){
    const history = useHistory()
    const imei = props.imei
    const [currentCompartmentIndex, setCurrentCompartmentIndex] = useState(1)
    const [information, setInformation] = useState("Please scan this compartment: ")
    const [compName, setCompName] = useState(null)
    const [compartmentUUID, setCompartmentUUID] = useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [scannerIsOpen, setScannerIsOpen] = useState(true);

    async function configureCompartment(){
        try {
            const requestBody = JSON.stringify({
                imei: imei,
                compartmentUUID: compartmentUUID,
                compartmentName: compName ? compName : "Compartment "+currentCompartmentIndex,
                compartmentIndex: currentCompartmentIndex
            })

            await api.put("/api/lockers/newcompartment", requestBody)

            if(currentCompartmentIndex+1>props.NumberOfCompartments){
                setCurrentCompartmentIndex(null)
                setScannerIsOpen(false)
                setInformation("You successfully scanned all the compartments")
            }
            else {setCurrentCompartmentIndex(currentCompartmentIndex+1)}

        } catch (error) {
            console.log(error);
            const failedIndex = currentCompartmentIndex;
            setCurrentCompartmentIndex(null)
            setCurrentCompartmentIndex(failedIndex)
            alert(`Something went wrong during PUT request (configureCompartment), you probably already scanned this compartment, please try again: \n${handleError(error)}`);
        }
    }

    const handleUpdate = (result) => {
        if(!result) {
            return null
        }
        const url = result.text
        const parts = url.split("/");
        setCompartmentUUID(parts[4])
        //stopp scanner
        setScannerIsOpen(false)
        //display modal
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false);
        setScannerIsOpen(true)
        configureCompartment()

    }


    Modal.setAppElement('#root');

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function redirectToAdminHome() {
        history.push('/admin');
    }


return (
    <BigContainer>
        <Label> {information} </Label>
            <Modal
                shouldCloseOnOverlayClick={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                data-backdrop="static" 
                data-keyboard="false"
            >
                <p>Please enter the name of the scanned Compartment. </p>
                <ColumnDiv>
                    <TextLabel> Name: </TextLabel>
                    <InputField
                        id='input'
                        maxLength="18"
                        style={{width: '75%'}}
                        onChange={e => {setCompName(e.target.value);}}
                        placeholder={currentCompartmentIndex}
                    />
                </ColumnDiv>
                <Button onClick={closeModal}> SAVE</Button>
            </Modal>
        <LockerContainer
            onClick={() => history.push({
                pathname: "/admin/setup",
                columns: props.columns,
                NumberOfCompartments: props.NumberOfCompartments
            })}
            columns={props.columns}>
            {Array.from({length: props.NumberOfCompartments}, (_, index) => index + 1).map((index) =>
                <CompartmentContainer
                    key={index}
                    selected={index==currentCompartmentIndex? true : false}
                    finished={index<currentCompartmentIndex ? true : false}
                    allFinished={information == "You successfully scanned all the compartments" ? true : false}
                />)}
        </LockerContainer>
        { scannerIsOpen ?
            <div style={{margin: "7%"}}>
                <BarcodeScannerComponent
                    width={500}
                    height={500}
                    onUpdate={(err,result) => {handleUpdate(result)}}/>
            </div>: null
        }
        <Button style={{marginRight: '20px'}} onClick={ () => redirectToAdminHome()}> Home </Button>
    </BigContainer>
);
}