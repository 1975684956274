import React, {useEffect, useState} from "react";
import Header from "../../views/Header";
import "../../views/stylesheet.css"
import {useHistory, useLocation} from "react-router";
import ModelLockerUI from "../../views/LockerUIs/ModelLockerUI";
import styled from "styled-components";
import {Button, InputField} from "../../views/designElements"
import {api, handleError} from "../../helpers/api";
import { useAlert } from 'react-alert'


const TextLabel = styled.p`
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 75px auto auto auto;
  grid-gap: 10px;
  grid-auto-rows: min-content;
  align-items: center;
`;

export default function SetUp(){
    const history = useHistory()
    const location = useLocation()
    const [name, setName] = useState(null)
    const [lockerLocation, setLockerLocation] = useState(null)
    const [imei, setImei] = useState(null)
    const [tenant, setTenant] = useState(null)
    const [printed, setPrinted] = useState(false)
    const [tenants, setTenants] = useState([])
    const [imeis, setImeis] = useState([])
    const [requiresValidation, setRequiresValidation] = useState(false)
    const popupAlert = useAlert()
    const [columns, setColumns] = useState(location.columns)
    const [NumberOfCompartments, setNumberOfCompartments] = useState(location.NumberOfCompartments)

    async function postLocker(){
        try {
            const requestBody = JSON.stringify({
                imei: imei,
                location: lockerLocation,
                name: name,
                tenant: tenant,
                requiresValidation: requiresValidation=="on"
            })

            await api.post("/api/lockers", requestBody)

        } catch (error) {
            console.log(error);
            alert(`Something went wrong during POST request (postLocker): \n${handleError(error)}`);
        }
    }

    async function lockerHasCompartments(){
        try {
            const response = await api.get("/api/lockers/"+imei+"/hasCompartments")
            return response.data
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (lockerHasCompartments): \n${handleError(error)}`);
        }
    }

    async function getTenants(){
        try {
            const response = await api.get("/api/tenants")
            setTenants(response.data)
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getTenants): \n${handleError(error)}`);
        }
    }

    async function getIMEIs(){
        try {
            const response = await api.get("/api/lockers/imei")
            setImeis(response.data)
        } catch (error) {
            console.log(error);
            alert(`Something went wrong during GET request (getIMEIs): \n${handleError(error)}`);
        }
    }

    function handleSubmit(){
        lockerHasCompartments(imei).then((hasCompartments) =>
            hasCompartments ?
                popupAlert.show("This IMEI has already got compartments assigned, please delete the exciting compartments first.", { type: "error" })
                : submit())
    }

    let submit = () => {
        postLocker()
        history.push({
            pathname: '/admin/scan',
            columns: location.columns,
            NumberOfCompartments: location.NumberOfCompartments,
            imei: imei
        })
    }

    useEffect(()=>{
        getTenants()
        getIMEIs()
    }, [])

    useEffect(()=>{
        if (columns != undefined) {
            localStorage.setItem('columns', columns)
        }
        if (NumberOfCompartments != undefined)  {
            localStorage.setItem('NumberOfCompartments', NumberOfCompartments)
        }
    });

    useEffect(()=>{
        const cl = localStorage.getItem('columns')
        const noc = localStorage.getItem('NumberOfCompartments')
        setColumns(cl)
        setNumberOfCompartments(noc)
    }, []);

    const [finishStatus, setfinishStatus] = useState(false);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Do you want to go back ? You will have to restart the entire process and may need to delete the locker from the database before retrying.")) {
                setfinishStatus(true)
                history.push("/admin");
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setfinishStatus(false)
            }
        }
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }, []);


    return (
        <div  style={{marginLeft: '7%', marginRight: '7%'}}>
            <Header/>
            <GridWrapper>
                {/*row 1*/}
                <div style={{gridColumn: '1 / 3', gridRow: 1, paddingBottom: '4%'}}>
                    chosen Model:
                    <ModelLockerUI
                        columns={columns}
                        NumberOfCompartments={NumberOfCompartments}
                        hover={false}
                    />
                </div>
                {/*row 2*/}
                <TextLabel style={{gridColumn: '1 / 3', gridRow: 2, paddingBottom: '4%'}}> Print out the QR codes and stick each of them to one compartment </TextLabel>
                <Button style={{gridColumn: '3 / 4', gridRow: 2}} onClick={() => setPrinted(true)}> Print out QR codes</Button>
                {/*row 3*/}
                <TextLabel style={{gridColumn: 1, gridRow: 3}}> Name: </TextLabel>
                <InputField style={{gridColumn: '2 / 3', gridRow: 3}}
                            key='input1'
                            maxLength="18"
                            onChange={e => {setName(e.target.value)}}/>
                {/*row 4*/}
                <TextLabel style={{gridColumn: 1, gridRow: 4}}> Location: </TextLabel>
                <InputField style={{gridColumn: '2 / 3', gridRow: 4}}
                            key='input1'
                            maxLength="18"
                            onChange={e => {setLockerLocation(e.target.value)}}/>
                {/*row 5*/}
                <TextLabel style={{gridColumn: 1, gridRow: 5}}> IMEI: </TextLabel>
                    <input style={{gridColumn: '2 / 3', gridRow: 5, display: 'inline-grid'}} type="text" list="imeis"
                           onChange={(e)=>{setImei(e.target.value)}}/>
                    <datalist id="imeis">
                        {imeis.map((imei) => <option key={imei} label={imei} value={imei}>{imei}</option>)}
                    </datalist>
                {/*row 6*/}
                <TextLabel style={{gridColumn: 1, gridRow: 6}}> Tenant: </TextLabel>
                    <select style={{gridColumn: '2 / 3', gridRow: 6, display: 'inline-grid'}}
                        onChange={(e)=>{setTenant(e.target.value)}}>
                        {tenants.map((tenant) => <option key={tenant.tenantId} label={tenant.name} value={tenant.name}/>)}
                    </select>
                {/*row 8*/}
                <div style={{gridColumn: '1 / 3', gridRow: 8, marginBottom: '5%'}}>
                    <input
                           type="checkbox" id="validation" name="requiresValidation"
                           onChange={e => {setRequiresValidation(e.target.value)}}/>
                        <label htmlFor="validation">    Require Phone Validation</label>
                </div>
                {/*row 9*/}
                <Button style={{gridColumn: '1 / 2', gridRow: 9}} disabled={!name || !lockerLocation || !imei} onClick={() => handleSubmit()}> Scan new Locker</Button>
                <Button style={{gridColumn: '3 / -1', gridRow: 9}} color={"#E6E5E5"} onClick={() => history.push("/admin")}> Cancel</Button>
                <div style={{gridColumn: '1 / 2', gridRow: 10, height: '40px'}}/>
            </GridWrapper>
        </div>
    );
}