import React, { useState } from "react";
import Header from "../../views/Header";
import "../../views/stylesheet.css"
import ModelLockerUI from "../../views/LockerUIs/ModelLockerUI";
import styled from "styled-components";

export const BigContainer = styled.div`
  width: 100%;
  height: 250px;
  border: none;
  margin-bottom: 20px;
  margin-left: 7%;
  display grid;
  grid-template-columns: ${props => props.lockers ? calculateGridWidth(props.lockers): "auto auto auto"};
  grid-template-rows: 1;
`;

function calculateGridWidth(lockers){
    let text = ""
    let max_width = 2
    for (let i = 0; i < max_width; i++) {
        text += lockers[i].columns*max_width*10 + "% ";
    }
    return text
}

export default function ChooseModel(){
    const [lockers, setLockers] = useState([
        {id: 1, columns: 3, numberOfCompartments:6, visible: true},
        {id: 0, columns: 2, numberOfCompartments:4, visible: true},
        {id: 3, columns: 3, numberOfCompartments:12, visible: true},
        {id: 2, columns: 2, numberOfCompartments:8, visible: true}
    ])

    return (
        <div>
            <Header/>
            <p style={{marginLeft: "9%"}}> Select the locker model: </p>
            <BigContainer lockers={lockers} >
                {lockers.map((locker) =>
                    locker.visible ?
                        <ModelLockerUI
                            key={locker.id}
                            columns={locker.columns}
                            NumberOfCompartments={locker.numberOfCompartments}
                            hover={true}/>
                        : <p>not visible</p>)}
            </BigContainer>
        </div>
    );
}