import React, { useEffect, useState } from "react";
import Header from "../../views/Header";
import { useHistory, useLocation } from 'react-router-dom';
import { api, handleError } from "../../helpers/api";
import {Button} from "../../views/designElements";
import ShareOverlay from "../../views/Share/ShareOverlay";

export default function Information(){
    const [ locationKeys, setLocationKeys ] = useState([])
    let history = useHistory();
    const location = useLocation();
    const successfull = location.successfull;
    const compartmentUUID = location.compartmentUUID;
    const compartmentName = location.compartmentName;
    const [doorStatus,setDoorStatus] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // GET door status data every few seconds 
    async function getDoorStatus(){
        try {
            const response = await api.get('/api/lockers/compartments/' + compartmentUUID)
            setDoorStatus(response.data.doorStatus);

        } catch (error) {
            console.log(error)
            alert(`Something went wrong during GET request: \n${handleError(error)}`)
        }
    }
    
    useEffect(() => {
        // This will fire only on mount.
        if (successfull) {
            if (doorStatus == true){
                const interval = setInterval(() => {
                    getDoorStatus();
                }, 1000);
                return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
            }
        }
    }, []) 

    useEffect(() => {
        if (successfull){
            const timeout = setTimeout(() => {
            if (getDoorStatus() != false){
                 // NOTIFY ADMIN VIA SMS OR EMAIL AFTER 5 MIN
            }
            return () => clearTimeout(timeout);
            },300000);
        }
    }, [])

    function redirectToScanningPage() {
        history.push('/scanner');
    }

    function redirectToHome() {
        history.push('/home');
    }

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
      
              history.push({
                pathname:'/home',
            })
      
      
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
      
              history.push({
                pathname:'/home',
            })
      
            }
          }
        })
      }, [ locationKeys, ])

    return (
        <div>
        <Header/>
        <div style={{marginLeft: '7%'}}>
            {!successfull && <p>Sorry, all compartments are occupied. Please come back later. </p>}
            {successfull && (doorStatus == "OPEN" ? <p>Opened compartment { compartmentName }. You can now use it.</p>: <p>Opening {compartmentName} ...</p>)}
            {successfull && doorStatus == "CLOSED" && <p>Closed compartment { compartmentName }. It is now locked. Rescan the QR code to open the compartment.</p>}
            {doorStatus == "CLOSED" && <Button style={{marginLeft: '7%', marginRight: '20px'}} onClick={ () => redirectToScanningPage()}>Scan QR Code again to unlock</Button>}
            {doorStatus == "CLOSED" && <Button style={{marginRight: '20px'}} onClick={ () => redirectToHome()}> Home </Button>}
            {successfull && doorStatus == "CLOSED" && <Button style={{marginBottom: "7%"}} disabled={disabled}
                                    onClick={() => {setModalIsOpen(!modalIsOpen); setDisabled(true) }}>Share</Button>}
            <ShareOverlay open={modalIsOpen} compartmentName={compartmentName} compartmentUUID={compartmentUUID}/>
        </div></div>
    );
}