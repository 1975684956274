import React from "react";
import "../stylesheet.css"
import styled from "styled-components";
import {useHistory} from "react-router";


const CompartmentContainer = styled.div`
    /* Demo-Specific Styles*/
    background-color: #FA991C;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%; 
    margin: 5px;
    border-radius: 20px;
    width: 100%;
    padding-bottom: 75%;

    /* Flex Fallback */
    @supports (display: block) {
    margin: 0;
    color: white;
    }
`;

const LockerContainer = styled.div`
    /* Grid Fallback */
    display: flex;
    flex-wrap: wrap;
    margin: 3%;
    padding: 3%;
    background: #E5E5E5;
    border-radius: 18px;
    max-width: 60%;
    &:hover {
        transform: ${props => props.hover ? "translateY(-2px)" : null};
        cursor: ${props => props.hover ? "pointer" : "default"};
    }

    /* Supports Grid */
    display: grid;
    grid-auto-rows: minmax(50px, auto);
    grid-template-columns: ${props => props.columns ? "auto ".repeat(props.columns) : "auto auto"};
    grid-gap: 10px;
`;


export default function ModelLockerUI(props){
    const history = useHistory()

    let redirectToSetUp = () => {
        history.push({
            pathname: '/admin/setup',
            columns: props.columns,
            NumberOfCompartments: props.NumberOfCompartments,
        })
    }

    return (
        <div>
            <LockerContainer
                onClick={() => redirectToSetUp()}
                columns={props.columns}
                hover={props.hover}>
                {Array.from({length: props.NumberOfCompartments}, (_, index) => index + 1).map((index) =>
                        <CompartmentContainer key={index}/>)}
            </LockerContainer>
        </div>
    );
}