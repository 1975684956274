import React from "react";
import "../stylesheet.css"
import styled from "styled-components";
import { useHistory } from "react-router";
import {Button, TextLabel} from "../designElements";


const CompartmentContainer = styled.div`
    /* Demo-Specific Styles backgroundColor: ${props => props.free ? "#FA991C" : "#1C768F"}*/
    background-color: ${props => props.free ? "#FA991C" : (props.occupiedByUser ? (props.shared ? "#619A56" : "#1c2149") : "#1C768F")};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;

    /* Flex Fallback */
    &:hover {
        transform: ${props => props.free ? "translateY(-2px);" : (props.occupiedByUser ? "translateY(-2px);" : "")};
        cursor: ${props => props.free ? "pointer" : (props.occupiedByUser ? "pointer" : "")};
    }
    box-shadow: ${props => props.free ? "0px 4px 4px rgba(0, 0, 0, 0.25);" : ""}; 
    margin-left: 5px;
    margin-right: 5px;
    flex: 1 1 200px;
    flex-direction: column;    
    border-radius: 25px;
    @supports (display: block) {
    margin: 0;
    color: ${props => props.occupiedByUser? "white" : "black"}
    }
`;

const LockerContainer = styled.div`
    /* Grid Fallback */
    display: flex;
    flex-wrap: wrap;

    /* Supports Grid */
    display: grid;
    grid-template-columns: repeat(${props => props.columns ? props.columns : "auto-fill"}, minmax(200px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1.5em;
    background: #E5E5E5;
    border-radius: 18px;
    margin: 7%;
    marginTop: 0%;
    marginBottom: 0%; 
    padding: 3%;
`;


function Compartment(props){
    const history = useHistory();
    console.log("props in OVerviwLockerUI")
    console.log(props)

    return (
        <CompartmentContainer
            free={props.free}
            shared={props.shared}
            occupiedByUser = {props.occupiedByUser}
            onClick={() =>
                props.free ?
                    (props.scanningRequired ?
                        history.push({
                            pathname: '/scanner',
                            hasToMatch: props.compartmentUUID,
                            lockerId: props.lockerId,
                            comingFromOverview: true}) :
                        history.push({
                            pathname: '/compartments/'+ props.compartmentUUID + '/open/',
                            comingFromOverview: true,
                            lockerId: props.lockerId})) :
                        (props.occupiedByUser ?
                                (props.scanningRequired ?
                                    history.push({
                                        pathname: '/scanner',
                                        hasToMatch: props.compartmentUUID,
                                        lockerId: props.lockerId,
                                        comingFromOverview: true,
                                        requiresVerification: props.requiresVerification
                                    }):
                            history.push({
                                pathname: '/compartments/'+ props.compartmentUUID + '/open',
                                comingFromOverview: true,
                                lockerId: props.lockerId})): null)}>
            <TextLabel> <b>{props.compartmentName}</b> </TextLabel>
            {props.free ? "Free": (props.occupiedByUser ? (props.shared?
                <Button color={"#E5E5E5"} style={{marginTop: '3%', width: '150px', height: '50px'}}
                        onClick={() =>
                    history.push({
                        pathname: '/scanner',
                        hasToMatch: props.compartmentUUID,
                        requiresVerification: props.requiresVerification,
                        lockerId: props.lockerId,
                        comingFromOverview: true,
                })}>Scan to unlock</Button>
                : "Press to unlock") : "Occupied")}
        </CompartmentContainer>
    );
}

export default function OverviewLockerUI(props){
    return (
        <div>
            <LockerContainer columns={props.columns}>
                {props.compartments.map((compartment) =>
                <div key={compartment.compartmentUUID+2}>
                    <Compartment
                        free={compartment.access.length === 0}
                        shared={props.shared}
                        requiresVerification={props.requiresVerification}
                        key={compartment.compartmentUUID}
                        compartmentUUID={compartment.compartmentUUID}
                        compartmentName={compartment.compartmentName}
                        lockerId={compartment.lockerId}
                        scanningRequired = {props.scanningRequired}
                        occupiedByUser={props.compartmentsOfUser.loading ? null : (props.compartmentsOfUser.data.some(item => item.compartmentUUID === compartment.compartmentUUID))}/>
                </div>)}
            </LockerContainer>
        </div>
    );
}